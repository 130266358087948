import React, { useState } from 'react';
import styled from 'styled-components';
import Store from 'src/stores/common-store';
import _ from 'lodash';
import 'twin.macro'

const StyledDiv = styled.div`
  width: 32%;
  margin: 0 auto;
  min-width: 320px;
  text-align: center;
  box-shadow: -1px 6px 8px #d8d8d887;
  margin-bottom: 20px;

  .card {
    flex: 1;
    box-shadow: -1px 6px 8px #d8d8d887;
  }
  .card-style {
  }
  .heading-bg {
    background: #9aca38;
  }
  .borderr {
    border-bottom: 1px solid #d7d7d7;
  }
  .cartButton {
    background: #fd6d0f;
    min-width: 140px;
    outline: none;
  }
`;
export default function BuyCard(props) {
  let { variants } = props;

  const [flavour, setflavour] = useState('Original');
  const [adding, setadding] = useState(false);
  //   console.log('propss...', props);
  return (
    <StyledDiv>
      {/* <div className="card  m-5"> */}
      <h1
        tw="text-black font-semibold p-2 text-center text-lg"
        className="heading-bg"
        style={{ backgroundColor: `${props.heading_color}` }}
      >
        {props.heading}
      </h1>
      <div
        tw="text-center p-2 text-sm italic" className="borderr"
        dangerouslySetInnerHTML={{ __html: props.sub_heading }}
      ></div>
      <div tw="text-center px-2" className="borderr">
        <div
          tw="text-4xl font-bold"
          style={{ color: `${props.price_text_color}` }}
        >
          {props.price}
        </div>
        <div tw="text-sm pb-1">per box</div>
      </div>
      <div tw="flex justify-center" className="borderr">
        <img src={props.img_src} alt="" />
      </div>
      <div tw="p-2 md:p-5 text-center text-base sm:h-48" className="borderr">
        {props.description}

        {props.subscription ? (

          <div>
            {/* <br />
            <br /> */}
            <div tw="text-center pb-3">I want to receive ProLon:</div>
            {/* <br /> */}
            <div tw="flex justify-center items-center">
              <input type="radio" checked />
              <div tw="mt-1 px-2 text-xs font-medium tracking-widest">
                EVERY MONTH
              </div>
            </div>
            <div tw="text-xs text-center pt-4 pb-4">
              *Plans can be cancelled anytime prior to shipping.
            </div>
          </div>
        ) : null}
      </div>
      <div tw="font-semibold p-2 md:p-3 text-sm text-center sm:h-20" className="borderr">
        {props.sub_description}
      </div>
      <div tw="flex justify-around items-center justify-center content-center h-12">
        <span tw="flex items-center justify-center">
          <input
            type="radio"
            name={'radio1' + props.cardId}
            id={'test1' + props.cardId}
            checked={flavour == 'Original' ? true : false}
            onClick={() => {
              setflavour('Original');
            }}
          />
          <label
            for={'test1' + props.cardId}
            tw="text-xs font-medium tracking-widest text-center px-2 mt-2"
          >
            ORIGINAL
          </label>
        </span>
        <span tw="flex items-center justify-center">
          <input
            type="radio"
            name={'radio1' + props.cardId}
            id={'test2' + props.cardId}
            checked={flavour == 'New Flavors' ? true : false}
            onClick={() => {
              setflavour('New Flavors');
            }}
          />
          <label
            for={'test2' + props.cardId}
            tw="text-xs font-medium tracking-widest text-center px-2 mt-2"
          >
            NEW FLAVORS
          </label>
        </span>
      </div>
      <div tw="flex justify-center items-center h-16">
        <button
          tw="text-white p-3  text-xs font-bold text-center px-8"
          className={`${adding ? 'btn--loading' : ''} cartButton`}
          onClick={async () => {
            setadding(true);
            let quantity = props.card.quantity;
            let newVariants = variants.map((e) => {
              let { selectedOptions } = e;
              if (selectedOptions && selectedOptions.length > 0) {
                selectedOptions.forEach((option) => {
                  e[option.name] = option.value;
                });
              }

              return e;
            });
            let variant = _.find(newVariants, (e) => {
              return e['Soup Flavor'] == flavour && e['Quantity'] == quantity;
            });

            let variantId = variant.shopifyId;

            let data = { variantId, quantity: 1, customAttributes: [] };
            if (props.card.subscription) {
              let attr = [
                {
                  key: 'subscription',
                  value: 'true',
                },
                {
                  key: 'order_interval_unit',
                  value: 'month',
                },
                {
                  key: 'order_interval_frequency',
                  value: '1',
                },
                {
                  key: 'charge_interval_frequency',
                  value: '1',
                },
              ];
              data.customAttributes = attr;
            }
            if (props.card.free_gift && props.card.free_gift != '') {
              data.customAttributes.push({
                key: '_free_gift',
                value: props.card.free_gift,
              });
            }

            await Store.addItem(data);
            Store.showCart();

            setadding(false);
          }}
        >
          {adding ? 'Adding...' : 'ADD TO CART'}
        </button>
      </div>
    </StyledDiv>
  );
}

// onClick={async () => {
// 	// await Store.addItem({ variantId: variants[0]?.shopifyId, quantity: 1 });

//   }}
